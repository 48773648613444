<template>
	<div v-html="privacyText" :key="key" class="privacy"></div>
</template>

<script>
import { getPolicyLang } from '@/api/policy.js';
export default {
	name: 'PrivacyComponent',
	data() {
		return {
			privacyText: '',
			key: 0
		};
	},

	async created() {
		await this.getPrivacyText();
	},

	methods: {
		async getPrivacyText() {
			const lang = window.localStorage.getItem('pui9_lang');
			const data = await getPolicyLang(lang, 'privacy');
			this.privacyText = data;
			this.key++;
		}
	},
	watch: {
		'$store.state.activeLanguage'(newVal) {
			if (newVal) {
				this.getPrivacyText();
			}
		}
	}
};
</script>

<style scoped>
.privacy {
	margin-top: 2%;
}
</style>
