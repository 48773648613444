<template>
	<div class="privacy">
		<div class="privacy__navbar">
			<Navbar />
		</div>
		<div class="privacy__header" :style="{ 'background-color': primaryColor }">
			<h1 class="privacy__header-title">{{ $t('router.privacy') }}</h1>
		</div>
		<div class="privacy__body" :style="{ 'background-color': 'white' }">
			<div class="privacy__body-sections">
				<PrivacyComponent />
			</div>
		</div>
		<footer class="privacy__footer">
			<Footer />
		</footer>
	</div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar.vue';
import PrivacyComponent from '@/components/privacy/PrivacyComponent.vue';
import Footer from '@/components/footer/Footer.vue';

export default {
	name: 'Privacy',
	title() {
		return `${this.$t('router.privacy')} | ${this.$store.state.eventInfo.evtrname}`;
	},
	components: {
		Navbar,
		PrivacyComponent,
		Footer
	},
	data() {
		return {};
	},
	mounted() {},
	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	},
	methods: {}
};
</script>

<style lang="scss" scoped>
.privacy {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__navbar,
	&__header,
	&__footer {
		flex: none;
	}
	&__body {
		overflow-y: hidden;
		flex: auto;
	}
}

.privacy__header {
	background-color: #565567;
	height: 15vh;
	display: flex;
	align-items: center;
}

.privacy__header-title {
	font-family: Cabin;
	font-weight: bold;
	font-size: 2rem;
	letter-spacing: 0.0625rem;
	color: #fff;
	padding-left: 10%;
	padding-top: 1.5rem;
}

.privacy__body {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.privacy__body-sections {
	height: 100%;
	display: flex;
	align-items: top;
	padding-left: 10%;
	padding-right: 10%;
}
</style>
